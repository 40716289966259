import "./globalStyles.scss";
import 'aos/dist/aos.css'
import "../node_modules/font-awesome/less/font-awesome.less";
import AOS from 'aos';
import Swal from 'sweetalert2';
AOS.init();

const FullScreenDialog = Swal.mixin({
  grow: 'fullscreen',
  background: '#EEEEEE',
  showCloseButton: true,
  showConfirmButton: false,
  animation: false,
  customClass: {
    popup: 'animated fadeInUp',
    closeButton: 'close-button-class'
  },
});

var html = '<div class="leaderModalContainer">' +
  '<div class="leaderBox" data-aos="fade-right" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<img src="/images/leader/1.png">' +
  '<div>' +
  '<div class="leaderName">Martin Hartono</div>' +
  '<div class="leaderTitle">CEO of GDP Venture</div>' +
  '</div>' +
  '</div>' +
  '<div class="leaderDetails"  data-aos="fade-left" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<p>Martin has a strong passion for gaming and technology, combined with a wealth of experience as Djarum Group’s Business Technology Director, led him in founding GDP Venture, a venture builder focusing on digital communities, media, commerce, and solution companies in 2010.</p></br></br>' +
  '<p>Martin loves teaching. Besides running GDP Venture as CEO, Martin spends his time mentoring local talents at the Founders Institute Jakarta. He believes, with the right mindset, process, infrastructure and determination, Indonesia can breed world-class technopreneurs, engineers, product developers, marketers and internet companies.</p></br></br>' +
  '<p>Martin is also an avid photographer, grappling enthusiast, basketball player, guitar slayer and doting father of three. He said, “Loving what you do is the best gift anyone can have.”</p></br>' +
  '</div>' +
  '</div>';
document.addEventListener('aos:in', ({
  detail
}) => {
  if (detail.id === 'about') {
    fadeOutTopAnimations();
    var mainLogo = document.getElementById('main-logo');
    mainLogo.src = "/images/logo.png";
    var header = document.getElementsByClassName("jobs-header")
    header[0].classList.add('about-header-effects');
    header[0].classList.remove('home-head');
    chooseOption('nav-about');
  } else if (detail.id === 'belief-page') {
    var header = document.getElementsByClassName("jobs-header")
    header[0].classList.remove('about-header-effects');
    header[0].classList.remove('home-head');
    header[0].classList.add('about-header-remove-effects');
  } else if (detail.id === 'contact') {
    var mainLogo = document.getElementById('main-logo');
    mainLogo.src = "/images/gdp logo white.png";
    var header = document.getElementsByClassName("jobs-header")
    header[0].classList.remove('about-header-effects');
    header[0].classList.remove('about-header-remove-effects');
    header[0].classList.add('home-head');
    chooseOption('nav-contact');
  } else if (detail.id === 'portfolio-page')
    chooseOption('nav-portfolio');
  else if (detail.id === 'jobs-page')
    chooseOption('nav-jobs')
  else if (detail.id === 'portfolio-page')
    chooseOption('nav-portfolio');
  else if (detail.id === 'news-page')
    chooseOption('nav-news')

});
document.addEventListener('aos:out', ({
  detail
}) => {
  if (detail.id === 'about') {
    fadeInTopAnimations();
    var mainLogo = document.getElementById('main-logo');
    mainLogo.src = "/images/gdp logo white.png";
    var header = document.getElementsByClassName("jobs-header")
    header[0].classList.remove('about-header-effects');
    header[0].classList.remove('about-header-remove-effects');
    header[0].classList.add('home-head');
    chooseOption('');

  } else if (detail.id === 'belief-page') {
    var header = document.getElementsByClassName("jobs-header")
    header[0].classList.add('about-header-effects');
    header[0].classList.remove('about-header-remove-effects');
    header[0].classList.remove('home-head');
    chooseOption('nav-about');
  } else if (detail.id === 'contact') {
    var header = document.getElementsByClassName("jobs-header")
    header[0].classList.remove('about-header-effects');
    header[0].classList.remove('home-head');
    header[0].classList.add('about-header-remove-effects');
    chooseOption('nav-news');
    var mainLogo = document.getElementById('main-logo');
    mainLogo.src = "/images/logo.png";
  } else if (detail.id === 'news-page') {
    chooseOption('nav-about');
  }
});

function chooseOption(option) {
  var classes = document.getElementsByClassName('nav-options');
  for (var i = 0; i < classes.length; i++) {
    var anchor = classes[i];
    if (anchor.id === option) {
      anchor.classList.add('nav-option-selected')
    } else {
      anchor.classList.remove('nav-option-selected')
    }
  }
}

function fadeOutTopAnimations() {
  var swipercompany = document.getElementsByClassName("swipercompany");
  swipercompany[0].classList.add('swipercompanyAnimate');

  var swipercompany = document.getElementsByClassName("video-wrapper-video");
  swipercompany[0].classList.add('animate-video-wrapper-video');
}

function fadeInTopAnimations() {
  var swipercompany = document.getElementsByClassName("swipercompany");
  swipercompany[0].classList.remove('swipercompanyAnimate');

  var swipercompany = document.getElementsByClassName("video-wrapper-video");
  swipercompany[0].classList.remove('animate-video-wrapper-video');
}